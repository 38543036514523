
  export default {
    name: `AeqCountriesField`,
    props: {
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      itemText: {
        type: String,
        default: `text`
      },
      itemValue: {
        type: String,
        default: `value`
      },
      label: {
        type: String,
        default: ``
      },
      value: {
        type: [String, Number, Array, Object],
        default: ``
      },
      loading: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filteredProps: {},
        innerValue: this.value
      }
    },
    async fetch() {
      await this.$store.dispatch(`formData/getCountries`)
    },
    computed: {
      traductedErrors() {
        return this.errorMessages.map((e) => this.$t(e))
      },
      innerItems() {
        return this.$store.state.formData.countries
      }
    },
    watch: {
      items: {
        deep: true,
        handler(newValue) {
          this.innerItems = newValue
        }
      }
    },
    mounted() {
      const parentObject = { $props: {} }
      this.filteredProps = Object.assign(parentObject.$props, this.$attrs)
      this.filteredProps.label = this.label
    },
    methods: {
      setValue(val) {
        this.innerValue = this.$helpers.wrapInArray(val)
      }
    }
  }
