
  export default {
    name: `PageHeader`,
    props: {
      iconUrl: {
        type: String,
        default: `/images/icons/operator.svg`
      },
      title: {
        type: String,
        default: null
      },
      message: {
        type: String,
        default: null
      },
      mdiIcon: {
        type: String,
        default: ``
      },
    }
  }
