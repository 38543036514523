
  import { mapGetters } from 'vuex'
  import IconBase from './IconBase.vue'
  import IconLogoMonogrammeAeqNoir from './icons/IconLogoMonogrammeAeqNoir.vue'

  export default {
    name: `MainMenu`,
    components: {
      IconBase,
      IconLogoMonogrammeAeqNoir
    },
    props: {},
    data() {
      return {
        innerValue: this.value
      }
    },
    computed: {
      ...mapGetters({
        companies: `companies/getUserCompanies`,
        currentCompany: `companies/getCurrentCompanyInfos`,
        isClient: `permissions/userIsClient`,
        isExternal: `permissions/userIsExternal`,
        screener: `external/getScreener`
      }),
      companiesLength() {
        return Object.keys(this.companies).length
      }
    },
    mounted() {},
    methods: {
      setCompany(company) {
        if (company.selectable) {
          this.$store.dispatch(`companies/changeCompany`, company.company_tag)
        }
      },
      setCompanyWithRedirect({ company, path = `/company-settings`, event }) {
        if (event) {
          event.preventDefault()
          event.stopPropagation()
        }
        this.$store.commit(`setRedirect`, path)
        this.$store.dispatch(`companies/changeCompany`, company.company_tag)
      },
      formatPhoneNumber(str) {
        return this.$helpers.formatPhoneNumber(str)
      },
      displayShortcut(company) {
        const isMasterAdmin = company.roles.includes(`masteradmin`)
        const isEnfantAdmin = company.roles.includes(`enfantadmin`)
        const companyIsParentEnfant = !!company.is_enfant || !!company.is_parent
        return (isMasterAdmin || isEnfantAdmin) && companyIsParentEnfant
      }
    }
  }
