
  export default {
    name: `AeqInputSearcherField`,
    props: {
      label: {
        type: String,
        default: ``
      },
      searchUrl: {
        type: String,
        default: ``
      },
      value: {
        type: Object,
        default: undefined,
      },
      hint: {
        type: String,
        default: ``
      }
    },
    data() {
      return {
        focused: false,
        innerValue: this.value,
        queryValue: ``,
        showModal: false,
        selectedBox: null,
        selectedResult: this.value || { name: ``, value: `` },
        loading: false,
        filteredProps: {},
      }
    },
    computed: {
      results() {
        return this.$store.getters[`inputSearcher/results`] || []
      },
      showResults() {
        return this.results.length > 0 && !this.loading
      },
      isDisabled() {
        return this.filteredProps?.disabled || false
      }

    },
    mounted() {
      const parentObject = { $props: {} }
      this.filteredProps = Object.assign(parentObject.$props, this.$attrs)
      this.filteredProps.label = this.label
    },
    methods: {
      openModal() {
        this.$store.commit(`inputSearcher/setResults`, [])
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      submitValue() {
        this.selectedResult = {};
        this.selectedResult.value = this.selectedBox;
        const resultName = this.results.find((r) => r.value === this.selectedBox);
        this.selectedResult.name = resultName.name;
        this.closeModal();
        this.$emit(`input`, this.selectedResult)
      },
      async search() {
        this.loading = true;
        await this.$store.dispatch(`inputSearcher/getResults`, {
          url: this.searchUrl,
          query: this.queryValue,
        })
        this.loading = false;
      }
    }
  }
