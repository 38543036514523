
  import Answer from '~/components/Answer'
  import AeqTimeline from '~/components/AeqTimeline'

  export default {
    name: `StepComponent`,
    components: {
      AeqTimeline,
      Answer
    },
    props: {
      token: {
        type: String,
        default: null
      },
      idx: {
        type: Number,
        default: null
      },
      reorder: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        default: undefined
      },
      readonly: {
        type: Boolean,
        default: false
      },
      headless: {
        type: Boolean,
        default: false
      },
      currentStep: {
        type: String,
        default: ``
      },
      infoBox: {
        type: Object,
        default: () => {}
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        timelines: false,
        previousButton: false,
        nextButton: true,
        internalAddButton: false,
        titleTemplate: {
          diploma: `%_pq_diploma_title%`,
          diploma_2: ` - %_pq_school_name%`,
          diploma_3: ` (%_pq_date_to_diploma|date,MM.YYYY%)`,
          work_experience: `%_pq_experience_type%`,
          work_experience_2: ` - %_pq_company_name%`,
          work_experience_3: ` - %_pq_job_title%`,
          work_experience_4: ` - %_pq_agency_name%`,
          work_experience_5: ` - %_pq_activity_title%`,
          work_experience_6: ` %_pq_date_from|date,(DD.MM.YYYY%`,
          work_experience_7: ` - %_pq_date_to|date,DD.MM.YYYY%`,
          work_experience_8: `%_pq_date_from|date,)%`,
          address: `%_pq_address_city%`,
          address_2: `, %_pq_country%`,
          address_3: ` %_pq_arrival_date|date,(MM.YYYY%`,
          address_4: ` - %_pq_date_of_departure|date,MM.YYYY%`,
          address_5: `%_pq_arrival_date|date,)%`
        },
        isReadonly: this.value.readonly || this.readonly,
        answerToDel: null,
        displayDeleteConfirmation: false,
        docAnswerID: null,
        documentsData: [],
        documentsDialog: false
      }
    },
    computed: {
      isMultiple() {
        return !!this.value.options?.multiple_answers?.value
      },
      answerContentClass() {
        let cssClass = `answer-content aeq-radius grey lighten-4`
        // if (this.value.answers.length > 1) {
        //   cssClass += 'top-rounded'
        // }
        if (!!this.value?.readonly) {
          cssClass += ` bgGrey  aeq-radius`
        }
        return cssClass
      },
      currentAnswer: {
        get() {
          return !this.isMultiple && this.value.currentAnswer === -1 ? 0 : this.value.currentAnswer
        },
        set(value) {
          const oldValue = this.value.currentAnswer
          const newValue = Number.isInteger(value) ? (value < 0 ? -1 : value) : oldValue

          if (oldValue !== newValue) {
            this.value.currentAnswer = newValue

            this.$emit(`change-answer`, {
              toIndex: newValue,
              stepID: this.value.id,
              fromIndex: oldValue
            })

            setTimeout(() => {
              const [domPanelHeader, defaultOffsetHeight] =
                oldValue + 1 === newValue
                  ? [this.$refs[`step_${this.value.id}_panel_header`][0], -12]
                  : !!this.$refs[`step_${this.value.id}_panel_header`]
                  ? [
                      this.$refs[`step_${this.value.id}_panel_header`][0],
                      this.$refs[`step_${this.value.id}_panel_header`][0].$el.clientHeight
                    ]
                  : [this.$refs[`step_${this.value.id}_panel_header`][0], 0]

              if (!!domPanelHeader) {
                this.goToTheTopStep({
                  target: domPanelHeader,
                  defaultOffsetHeight
                })
              }
            }, 500)
          }
        }
      },
      activeErrors() {
        return this.getErrors()
      }
    },
    watch: {
      'value.answers': {
        deep: true,
        handler() {
          this.value.updated_at = new Date().toISOString()
        }
      },
      activeErrors: {
        deep: true,
        handler(newErrors) {
          const arrayErrors = Object.values(newErrors)
          const nbErrors = arrayErrors.reduce((acc, errors) => acc + errors.length, 0)
          const id = this.value.id

          if (nbErrors > 0) {
            const text = this.$tc(`pages.screenings.stepErrorMessage`, nbErrors, { countError: nbErrors })
            this.screeningsErrorsADD_LIST({ id, text })
          }
        }
      }
    },
    created() {},
    mounted() {
      console.log(`STEP`, this.value)

      console.log(this.reorder)
      // Sort answers
      if (this.reorder && !!this.$helpers && !!this.$helpers.sortElements) {
        this.value.form_elements = this.$helpers.sortElements(
          this.value.form_elements,
          (a, b) => a[1].order - b[1].order
        )
      }

      this.$watch(() => {
        if (
          !!this.isActive &&
          this.value.currentAnswer >= 0 &&
          this.value.answers.length >= 1 &&
          this.value.answers.length > this.value.currentAnswer
        ) {
          const refName = this.value.answers[this.value.currentAnswer].id
          const element = !!this.$refs[refName] ? this.$refs[refName][0] : null
          if (element) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.$helpers.focus({
                  element: element?.$el?.getElementsByTagName(`input`)
                })
              })
            })
          }
        }
      })
    },
    methods: {
      getDOMAnswer(idx) {
        const answerUUID = this?.value?.answers?.[idx]?.id
        const el = this?.$refs?.[answerUUID]

        if (!el) {
          return null
        }

        return el[0] || el
      },
      getDOMAnswerFromId(id) {
        return this.getDOMAnswer(this.value.answers.findIndex((a) => a.id === id))
      },
      openLastAnswer() {
        this.openAnswer(this.value.answers.length - 1)
      },
      openAnswer(idx) {
        this.currentAnswer = idx
      },
      addAnswer() {
        if (!this.isReadonly) {
          this.$emit(`add-answer`, this.value.id)
        }
      },
      openDocumentsModal(answerID) {
        const answer = this.value.answers.find((step) => step.id === answerID)
        this.docAnswerID = answerID
        this.documentsData = answer.uploaded_documents
        this.documentsDialog = !this.documentsDialog
      },
      confirmDel(index) {
        this.answerToDel = index
        this.displayDeleteConfirmation = true
      },
      closeConfirm() {
        this.answerToDel = null
        this.displayDeleteConfirmation = false
      },
      templatetitle(answer, value) {
        const pattern = value?.pattern
        return !!answer.title && !!value?.empty_answer_title
          ? value.empty_answer_title.split(` `).join(``) !== answer.title.split(` `).join(``)
            ? answer.title
            : `${this.$helpers.parseTemplate(
                this.titleTemplate[pattern],
                answer.data,
                value.form_elements,
                value.empty_answer_title
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_2`],
                answer.data,
                value.form_elements,
                ``
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_3`],
                answer.data,
                value.form_elements,
                ``
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_4`],
                answer.data,
                value.form_elements,
                ``
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_5`],
                answer.data,
                value.form_elements,
                ``
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_6`],
                answer.data,
                value.form_elements,
                ``
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_7`],
                answer.data,
                value.form_elements,
                ``
              )}${this.$helpers.parseTemplate(
                this.titleTemplate[`${pattern}_8`],
                answer.data,
                value.form_elements,
                ``
              )}`
          : !!answer.title
          ? answer.title
          : this.$t(`components.no_title`)
      },
      delAnswer(index) {
        if (typeof index === `number` && index >= 0) {
          this.$emit(`del-answer`, {
            stepId: this.value.id,
            stepIndex: this.idx,
            answerId: this.value?.answers[index]?.id,
            answerIndex: index
          })
        }
        this.closeConfirm()
      },
      async nextAnswer() {
        const domAnswer = this.getDOMAnswer(this.value.currentAnswer)

        if (!domAnswer) {
          return
        }

        const fieldsValid = await domAnswer.validate()

        if (!Object.values(fieldsValid).some((err) => err.length > 0)) {
          this.$emit(`send-answer`, {
            answer: this.value.answers[this.value.currentAnswer],
            stepID: this.value.id,
            answerIndex: this.value.currentAnswer
          })
        } else {
          this.goToTheTopStep({ id: this.value.id, defaultOffsetHeight: 80 })
        }
      },
      goToTheTopStep({ target, id, defaultOffsetHeight = 20 }) {
        if (target) {
          const headerHeight = document.querySelector(`header`)?.offsetHeight || 0
          const offset = defaultOffsetHeight + headerHeight
          setTimeout(() => {
            this.$vuetify.goTo(target, {
              offset
            })
          }, 500)
        } else if (id) {
          this.$emit(`scroll-to`, {
            id
          })
        }
      },
      previousAnswer() {
        this.value.currentAnswer -= 1
      },
      dumpValidate() {
        const answerComponents = this.value.answers.map((el, i) => this.getDOMAnswer(i))
        const numberErrorForTheAnswers = answerComponents.reduce((previousValue, answer) => {
          return (
            previousValue +
            Object.values(answer.getErrors()).reduce((previousValue, question) => previousValue + question.length, 0)
          )
        }, 0)

        const id = this.value.id

        if (numberErrorForTheAnswers > 0) {
          const text = this.$tc(`pages.screenings.stepErrorMessage`, numberErrorForTheAnswers, {
            countError: numberErrorForTheAnswers
          })
          this.screeningsErrorsADD_LIST({ id, text })
          this.value.isComplete = false
        } else {
          this.$store.commit(`screeningsErrors/REMOVE_LIST`, { id })
        }
      },
      async validate(options, changeStep = true) {
        if (this.isReadonly) {
          return
        }

        // Get all Answer components & trigger validation
        const answerComponents = this.value.answers.map((el, i) => this.getDOMAnswer(i))
        const answerErrors = await Promise.all(answerComponents.map((s) => s.validate(options)))

        this.$emit(`error`, {
          stepId: this.value.id,
          answers: answerErrors
        })
        const isComplete = this.value.answers.every((a) => a.isValid)
        if (!!changeStep) {
          this.value.isComplete = isComplete
        }
        this.$emit(`input`, this.value)
        return isComplete
      },
      getErrors() {
        const validationObservers = this.value.answers
          .map((el, i) => ({
            index: i,
            obs: this.getDOMAnswer(i)
          }))
          .filter((o) => !!o.obs && typeof o.obs.getErrors === `function`)
        return validationObservers
          .map((el) => [el.index, Object.values(el.obs.getErrors()).filter((err) => err.length > 0)])
          .reduce((accum, [k, v]) => {
            accum[k] = v
            return accum
          }, {})
      },
      setErrors(stepErrors) {
        // Update timeline
        if (!!stepErrors?.timeline) {
          this.value.timeline = stepErrors.timeline
          this.value.updated_at = new Date().toISOString()
          this.value.refreshTimeline += 1
        }
        // Answer errors
        if (!!stepErrors.answers) {
          // let moved = false
          for (const i in stepErrors.answers) {
            const DOMAnswer = this.getDOMAnswer(i)
            if (!!stepErrors.answers[i].fields) {
              this.currentAnswer = i
              if (!!stepErrors.answers[i].fields?.data) {
                DOMAnswer.setErrors(stepErrors.answers[i].fields.data)
              } else {
                DOMAnswer.setErrors(stepErrors.answers[i].fields)
              }
              break
            }
          }
          this.value.isComplete = this.value.answers.every((a) => a.isValid)
          this.$emit(`input`, this.value)
        }

        // Step errors
        if (!!stepErrors.errors && stepErrors.errors.length > 0) {
          const id = this.value.id
          if (stepErrors.errors.length === 1) {
            const text = stepErrors.errors[0]
            this.screeningsErrorsADD_LIST({ id, text })
          } else {
            const text = this.$tc(`pages.screenings.stepErrorMessage`, stepErrors.errors.length, {
              countError: stepErrors.errors.length
            })
            this.screeningsErrorsADD_LIST({ id, text })
          }
          this.$emit(`error`, { stepId: this.value.id, errors: this.$helpers.wrapInArray(stepErrors.errors) })
          this.value.isComplete = false
          this.$emit(`input`, this.value)
          this.goToTheTopStep({ id: this.value.id, defaultOffsetHeight: 80 })
        } else {
          this.value.isComplete = !stepErrors.answers
        }
      },
      _scrollTo(e) {
        this.goToTheTopStep({ id: this.value.id, defaultOffsetHeight: 80 })
      },
      screeningsErrorsADD_LIST({ id, text }) {
        this.$store.commit(`screeningsErrors/ADD_LIST`, {
          id,
          text
        })
      },
      parentHasInfobox() {
        if (!this?.infoBox) {
          return false
        }
        const { comments = [], errors = [], hints = [] } = this.infoBox
        return (
          [
            ...comments.filter((comment) => comment.id === this.currentStep),
            ...errors.filter((error) => error.id === this.currentStep),
            ...hints.filter((hint) => hint.id === this.currentStep)
          ].length > 0
        )
      },
      goToFirstError() {
        const errors = this.getErrors()
        for (const i in errors) {
          if (errors[i].length > 0) {
            this.goToTheTopStep({ target: `#step_${this.value.id}_panel_${i}` })
            return
          }
        }
      },
      deleteDocument(doc) {
        this.$emit(`delete-doc`, { doc, stepId: this.value.id, answerId: this.docAnswerID })
      }
    }
  }
