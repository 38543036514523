
  export default {
    name: `AeqTextBlock`,
    props: {
      label: {
        type: String,
        default: ``
      },
      hint: {
        type: String,
        default: ``
      }
    },
    data() {
      return {}
    }
  }
