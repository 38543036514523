
  import FullCalendar from '@fullcalendar/vue'
  import momentPlugin from '@fullcalendar/moment'
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
  import interactionPlugin from '@fullcalendar/interaction'

  export default {
    name: `AeqTimeline`,
    components: {
      FullCalendar
    },
    props: {
      screening: {
        type: Object,
        default: null
      },
      step: {
        type: Object,
        default: null
      },
      events: {
        type: Array,
        default: () => []
      },
      filter: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        gaps: this.events.filter((e) => e.type === `gap`),
        answerEvents: this.events.filter((e) => e.type === `event`),
        periods: this.events.filter((e) => e.type === `period`),
        calendarOptions: {
          plugins: [momentPlugin, resourceTimelinePlugin, interactionPlugin],
          locales: [`en`, `fr`, `de`, `it`],
          locale: this.$i18n.locale,
          initialView: `resourceTimelineAEQ`,
          resourceGroupField: !!this.screening ? `stepName` : undefined,
          events: [],
          resources: [],
          contentHeight: `auto`,
          customButtons: {
            reset: {
              text: this.$t(`components.reset_view`),
              click: () => this.getCalendar().gotoDate(this.getMoment().subtract(6, `months`).format(`YYYY-MM-DD`))
            }
          },
          initialDate: this.getMoment().subtract(6, `months`).format(`YYYY-MM-DD`),
          headerToolbar: {
            left: `reset`,
            center: `title`,
            right: `prevYear,nextYear`
          },
          views: {
            resourceTimelineAEQ: {
              type: `resourceTimeline`,
              nowIndicator: true,
              resourceAreaHeaderContent: this.$t(`components.cover_period`),
              slotDuration: { months: 1 },
              duration: { years: 1 },
              slotLabelFormat: [{ year: `numeric` }, { month: `short` }],
              titleFormat: {
                year: `numeric`
              }
            }
          },
          schedulerLicenseKey: `CC-Attribution-NonCommercial-NoDerivatives`
        },
        refreshComponent: 0
      }
    },
    mounted() {
      if (this.events.length > 0) {
        if (!!this.screening) {
          this.calendarOptions.resources = this.parseResources(this.screening)
        } else if (!!this.step) {
          this.calendarOptions.resources = this.parseResources(this.step)
        }
        this.calendarOptions.events = this.parseEvents()
        const range = this.getPeriodLimits(this.calendarOptions.events)
        this.calendarOptions.views.resourceTimelineAEQ.validRange = range
        this.calendarOptions.views.resourceTimelineAEQ.titleFormat =
          range.start.format(`MM.YYYY`) + ` - ` + range.end.format(`MM.YYYY`)

        this.refreshComponent += 1
      }
    },
    methods: {
      getMoment(date) {
        if (date !== undefined && date !== null) {
          // eslint-disable-next-line no-undef
          return !!this.$moment ? this.$moment(date) : moment(date)
        } else {
          // eslint-disable-next-line no-undef
          return !!this.$moment ? this.$moment() : moment()
        }
      },
      refreshTimeline() {
        this.refreshComponent += 1
      },
      getAllRessourcesIds() {
        if (!!this.screening) {
          return this.screening?.steps.map((s) => s.answers.map((a) => `ans_${a.id}`)).flat()
        } else if (!!this.step) {
          return this.step.answers.map((a) => `ans_${a.id}`)
        }
        return []
      },
      getRessourcesIds(stepId) {
        const steps = this.screening?.steps.filter((s) => s.id === stepId)
        return !!steps ? steps.map((s) => s.answers.map((a) => `ans_${a.id}`)).flat() : []
      },
      getPeriodLimits(events) {
        let viewStart = this.getMoment()
        let viewEnd = this.getMoment()

        for (const event of events) {
          const eventStart = this.getMoment(event.start)
          const eventEnd = this.getMoment(event.end)
          if (eventStart.isBefore(viewStart)) {
            viewStart = eventStart
          }
          if (eventEnd.isAfter(viewEnd)) {
            viewEnd = eventEnd
          }
        }

        return {
          start: viewStart,
          end: viewEnd
        }
      },
      parseEvents() {
        const data = []

        // Add periods
        data.push(
          this.periods.map((p) => ({
            title: p.title,
            start: p.start,
            end: p.end,
            resourceIds: p.stepIds
              ? p.stepIds.map((sId) => this.getRessourcesIds(sId)).flat()
              : this.getAllRessourcesIds(),
            display: `background`,
            backgroundColor: `#8b8b8b`
          }))
        )

        // Add Gaps
        data.push(
          this.gaps.map((g) => ({
            title: g.title,
            start: g.start,
            end: g.end,
            resourceIds: g.stepIds.map((sId) => this.getRessourcesIds(sId)).flat(),
            display: `background`,
            classNames: `solid-background`,
            backgroundColor: `#aa0000`
          }))
        )

        // Add events
        data.push(
          this.answerEvents.map((e) => ({
            title: e.title,
            start: e.start,
            end: e.end,
            resourceIds: e.answerIds.map((id) => `ans_${id}`)
          }))
        )

        return data.flat()
      },
      parseResources(data) {
        // Extract resources
        if (!!data) {
          if (!!data.steps) {
            return data.steps
              .filter((s) => this.filter.includes(s.name))
              .map((s) =>
                s.answers.map((a) => ({
                  id: `ans_${a.id}`,
                  title: !!a.title ? a.title : s.label,
                  name: a.name,
                  stepName: s.label
                }))
              )
              .flat()
          } else if (!!data.answers) {
            return data.answers.map((a) => ({
              id: `ans_${a.id}`,
              title: !!a.title ? a.title : data.label,
              name: a.name
            }))
          }
        }
        return []
      },
      getCalendar() {
        return !!this.$refs.timeline ? this.$refs.timeline.getApi() : null
      }
    }
  }
