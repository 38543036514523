
  import Screening from '~/components/Screening'
  import AeqTimeline from '~/components/AeqTimeline'
  import PageHeader from '~/components/PageHeader'

  export default {
    name: `ShowScreeningPage`,
    components: { PageHeader, AeqTimeline, Screening },
    validate({ params }) {
      // Must be a number
      return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(params.id)
    },
    data() {
      return {
        fab: false,
        loading: true,
        id: this.$route.params.id,
        workingData: null,
        screeningCompany: null,
        events: [],
        refreshTimeline: 0,
        dialog: false,
        comments: [],
        menu: null,
        valid: false
      }
    },
    head() {
      return {
        title: this.$t(`pages.screenings.screening`)
      }
    },
    computed: {
      storedData: {
        get() {
          return this.$store.getters[`screenings/getData`](this.id)
        },
        set(srcData) {
          const srcDataWithoutNewDocuments = this.$helpers.removeNewDocumentFromScreening(srcData)
          if (JSON.stringify(this.storedData) !== JSON.stringify(srcDataWithoutNewDocuments)) {
            this.$store.commit(`screenings/setData`, srcDataWithoutNewDocuments)
          }
        }
      },
      screener() {
        return !!this.storedData?.screener ? this.storedData.screener : null
      },
      userIsScreener() {
        return this.$store.getters[`permissions/userIsScreener`]
      }
    },
    beforeCreate() {
      if (!!this.$store.state.formData.loadCountries) {
        this.$store.commit(`formData/toggleCountries`)
      }
    },
    beforeMount() {
      this.$nextTick(function () {
        this.$nuxt.$loading.start()
      })
    },
    async mounted() {
      this.$store.commit(`screenings/setLastScreening`, this.id)
      this.getScreening(this.id).then((res) => {
        const localLastDate = this.getLastUpdateDate(this.storedData)
        const apiLastDate = this.getLastUpdateDate(res)
        // We reload the screening if vuex is empty, if there is new data or if the lang is different.
        const dataLang = res?.data?.lang
        const storedData = this?.storedData?.lang
        if (
          this.userIsScreener ||
          !this.storedData ||
          apiLastDate > localLastDate ||
          dataLang !== storedData ||
          !res?.data?.editable ||
          !!res?.data?.force_reload
        ) {
          console.log(`API used.`)
          // Reorder steps and questions
          const stepsTemps = this.$helpers.sortElements(res.steps, (a, b) => a[1].order - b[1].order)
          let currentStep = {}

          if (!!this.storedData && res.lang !== this.storedData.lang) {
            currentStep = this.storedData.currentStep
            stepsTemps.map((s, i) => {
              s.currentAnswer = this.storedData?.steps?.[i].currentAnswer
              return s
            })
          }
          this.workingData = { ...res, steps: stepsTemps, currentStep }
          this.saveLocally(`created`)
        } else {
          console.log(`VUEX used.`)
          this.workingData = { ...this.storedData }
        }

        // Change local company
        if (this.$store.getters[`companies/userHasCompany`](this.workingData?.company_tag)) {
          this.screeningCompany = this.$store.getters[`companies/getUserCompanies`][this.workingData?.company_tag]
        } else {
          this.$flashMessage.error({
            title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
            message: this.$t(`messages.forbidden`)
          })
        }
      })
      await this.getComments()
    },
    updated() {
      this.$nextTick(function () {
        this.$nuxt.$loading.finish()
        this.loading = false
      })
    },
    methods: {
      saveLocally(evt) {
        this.storedData = this.workingData
      },
      getLastUpdateDate(scrData) {
        if (!scrData) {
          return 0
        }
        return scrData.steps.reduce((currentValue, step) => {
          return Math.max(
            currentValue,
            !!step.updated_at
              ? Date.parse(step.updated_at)
              : step.answers.reduce((currentValueAnswers, stepAnswers) => {
                  return Math.max(currentValueAnswers, Date.parse(stepAnswers.updated_at))
                }, 0)
          )
        }, 0)
      },
      getScreening(id) {
        const lang = !!this.$route.query.lang ? this.$route.query.lang : this.$i18n.locale
        return this.$api.screenings.get(id, lang)
      },
      async getComments() {
        try {
          this.comments = await this.$api.screenings.all_comments(this.id)
        } catch (e) {
          console.log(e)
        }
      },
      addAnswer(stepId) {
        this.$nuxt.$loading.start()
        this.$api.screeningSteps
          .new(stepId)
          .then((res) => {
            const targetStep = this.workingData.steps.find((s) => s.id === stepId)
            if (!!res && !!targetStep) {
              this.workingData = {
                ...this.workingData,
                steps: [
                  ...this.workingData.steps.map((step) =>
                    step.id === stepId
                      ? {
                          ...step,
                          isComplete: false,
                          answers: [...step.answers, { ...res }],
                          currentAnswer: step.answers.length
                        }
                      : step
                  )
                ]
              }
            }
            this.saveLocally(`From addAnswer`)
            this.$store.dispatch(
              `flashMessages/messageSuccess`,
              { message: this.$i18n.t(`messages.action_answer_add_confirmed`) },
              { root: true }
            )
          })
          .catch((err) => {
            console.log(`Step error response`, err.response, err)
            const message = err?.response?.data?.detail || err?.response?._data?.detail || this.$t(`messages.error`)
            this.$store.dispatch(`flashMessages/messageError`, { message }, { root: true })
          })
          .finally(() => {
            this.$nextTick(function () {
              this.$nuxt.$loading.finish()
            })
          })
      },
      answerExist(stepIndex, answerIndex) {
        return !!this.workingData?.steps[stepIndex]?.answers[answerIndex]
      },
      delAnswerFromIndex(stepIndex, answerIndex) {
        if (this.answerExist(stepIndex, answerIndex)) {
          this.workingData.steps[stepIndex].answers.splice(answerIndex, 1)
        }
        this.saveLocally(`From delAnswerFromIndex`)
      },
      delAnswer(data) {
        this.$nuxt.$loading.start()
        this.$api.screeningAnswers
          .delete(data.answerId)
          .then((res) => {
            this.delAnswerFromIndex(data.stepIndex, data.answerIndex)
            const currentStepInstance = this.$refs.screening.getCurrentStepInstance()
            if (!!currentStepInstance) {
              if (Object.values(currentStepInstance.getErrors()).length > 0) {
                currentStepInstance.validate({ silent: true })
              }
              currentStepInstance.openLastAnswer()
            }
            this.$store.dispatch(
              `flashMessages/messageSuccess`,
              { message: this.$i18n.t(`messages.action_answer_delete_confirmed`) },
              { root: true }
            )
          })
          .catch((err) => {
            // Validation errors
            if (err?.response?.status === 404) {
              this.$store.dispatch(
                `flashMessages/messageError`,
                { message: this.$i18n.t(`messages.page_not_found`) },
                { root: true }
              )
            }
          })
          .finally(() => {
            this.$nextTick(function () {
              this.$nuxt.$loading.finish()
            })
          })
      },
      sendAnswer(data) {
        // Already locally validate if here
        if (!!data?.answer?.id && !!data.stepID && data.answerIndex !== undefined) {
          this.$nuxt.$loading.start()
          this.$api.screeningAnswers
            .sendAnswer(data.answer.id, data.answer)
            .then((res) => {
              if (!!res) {
                const step = this.workingData.steps.find((s) => s.id === data.stepID)
                step.answers[data.answerIndex] = res
                const DOMSteps = this.$refs.screening.getDOMSteps(step.id)
                DOMSteps.currentAnswer += 1
              }
            })
            .catch((err) => {
              // Validation errors
              if (err?.response?.status === 400) {
                // Send errors into Screening component
                if (!!err?.data) {
                  const DOMAnswer = this.$refs.screening.getDOMSteps(data.stepID).getDOMAnswer(data.answerIndex)
                  // FIXME Remove answers and fields objects from error 400 payload (backend)
                  const errors = err.data?.answers?.[0]?.fields
                  if (!!DOMAnswer && !!errors) {
                    const { data } = errors
                    DOMAnswer.setErrors(data || errors)
                    this.$refs.screening.goToStep(DOMAnswer)
                  }
                }
              }
            })
            .finally(() => {
              this.$nextTick(function () {
                this.$nuxt.$loading.finish()
              })
            })
        }
      },
      closeAnswers(evt) {
        if (!!this.workingData.steps[evt.previousStepIdx]) {
          // Open first Answer incomplete or let it closed if none
          this.workingData.steps[evt.previousStepIdx].currentAnswer = this.workingData.steps[
            evt.previousStepIdx
          ].answers.findIndex((a) => !a.isValid)
        }
        this.saveLocally(`From closeAnswers`)
      },
      sendStep(step) {
        // Trigger validation & send if locally valid
        if (!!step && !!step.id) {
          const validatePromise = this.$refs.screening.validate(step.id, false)
          if (!!validatePromise && !!step.editable) {
            validatePromise.then((res) => {
              if (res) {
                this.$nuxt.$loading.start()
                this.$api.screeningSteps
                  .send(step.id, step)
                  .then((res) => {
                    this.$refs.screening.setErrors({
                      step_errors: { id: res.id, errors: [] }
                    })
                    if (!!res) {
                      const id = this.workingData.steps.findIndex((s) => s.id === step.id)
                      const { form_elements: formElements, ...stepWithoutFormElements } = res
                      this.workingData.steps[id] = {
                        ...this.workingData.steps[id],
                        ...stepWithoutFormElements,
                        refreshTimeline: step.refreshTimeline + 1
                      }
                    }
                    this.$refs.screening.nextStep()
                  })
                  .catch((err) => {
                    // Validation errors
                    if (err?.response?.status === 400) {
                      // Send errors into Screening component
                      if (!!err?.data && !!this.$refs.screening) {
                        this.$refs.screening.setErrors(err.data)
                        console.log(err?.data)
                        if (Array.isArray(err?.data) && err?.data.length === 1 && typeof err?.data[0] === `string`) {
                          this.$flashMessage.error({
                            title: `${this.$t(`messages.error`)}`,
                            message: err?.data[0]
                          })
                        }
                      }

                      // Update General timeline
                      if (!!err?.data?.timeline) {
                        // FIXME Resources ids manquants
                        for (const ev of err.data.timeline) {
                          if (ev.type === `gap`) {
                            this.events.push(ev)
                          }
                        }
                      }
                    }
                  })
                  .finally(() => {
                    this.$nextTick(function () {
                      this.$nuxt.$loading.finish()
                    })
                  })
              }
            })
          }
        }
      },
      sendScreening() {
        this.$nuxt.$loading.start()
        this.$api.screenings
          .send(this.workingData)
          .then((res) => {
            this.$flashMessage.info({
              title: `Successful`,
              message: res.message
            })
            this.$router.push({ path: `/candidate/` })
            this.$refs.screening.setErrors({ errors: [] })
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              if (!!err?.data && !!this.$refs.screening) {
                this.$refs.screening.setErrors(err.data, true)
              }
            }
          })
          .finally(() => {
            this.dialog = false
            this.$nextTick(function () {
              this.$nuxt.$loading.finish()
            })
          })
      }
    }
  }
