
  export default {
    name: `AeqSwitchField`,
    props: {
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        innerValue: this.value
      }
    },
    computed: {
      traductedErrors() {
        return this.errorMessages.map((e) => this.$t(e))
      }
    },
    watch: {
      innerValue(val) {
        this.$emit(`input`, val)
      }
    },
    mounted() {},
    methods: {}
  }
