
  export default {
    name: `ScreeningComponent`,
    props: {
      token: {
        type: String,
        default: null
      },
      reorder: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        default: undefined
      },
      comments: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        allComplete: true,
        displayErrors: [],
        stepHint: [],
        stepErrors: {},
        openMobileError: {},
        openModalNewDocumentsValue: false,
        newDocumentsStep: null,
        newDocumentsAnswer: null,
        validationStep: true,
        commandButton: true,
        counter: 0,
        docStepID: 0,
        documentsRequirements: null,
        documentsDialog: false,
        documentsData: null
      }
    },
    computed: {
      displayingStep: {
        get() {
          return this.value.currentStep + 1
        },
        set(value) {
          const oldValue = this.value.currentStep
          const newValue = Number.isInteger(value) ? value - 1 : this.value.currentStep
          if (oldValue === newValue) {
            this.value.currentStep = -1
          } else {
            this.value.currentStep = newValue
            if (!!this.getCurrentStep()) {
              this.goToStep(this.getCurrentStep().id)
            }
            this.$emit(`change-step`, { previousStepIdx: oldValue, currentStepIdx: newValue })
            this.focus(newValue)
          }
        }
      },
      infoBox() {
        return { comments: this.comments, hints: this.stepHint }
      }
    },
    watch: {
      'value.steps': {
        deep: true,
        handler(value) {
          if (!!this.value?.steps[this.displayingStep]?.complete) {
            this.getCurrentStepInstance().forceUpdate()
            // this.$forceUpdate()
          }
          if (!!this.getCurrentStep()) {
            this.getCurrentHeaderStepInstance()?.forceUpdate()
          }
        }
      }
    },
    created() {
      if (this.value) {
        // Init rendering data
        if (!this.value?.currentStep) {
          this.$set(this.value, `currentStep`, 0)
        }

        const commentsScreening = this.comments.filter((comment) => comment.resource_id === this.value.id)

        this.$set(this.value, `commentsScreening`, commentsScreening)

        this.value.steps.forEach((step, i) => {
          this.allComplete = this.allComplete && step.isComplete
          !!step.explanation_text &&
            (this.stepHint = [
              ...this.stepHint,
              {
                id: step.id,
                text: step.explanation_text,
                type: `explanationText`
              }
            ])

          this.$set(this.value.steps[i], `refreshTimeline`, 0)

          const commentsStep = this.comments.filter((comment) => comment.resource_id === step.id)

          this.$set(
            this.value.steps[i],
            `commentsStep`,
            commentsStep.map((item) => ({ ...item, type: `commentsStep` }))
          )

          !this.value.steps?.[i]?.currentAnswer && this.$set(this.value.steps[i], `currentAnswer`, 0)

          this.value.steps[i].answers.forEach((answer, id) => {
            const commentsAnswer = this.comments.filter((comment) => comment.resource_id === answer.id)
            this.$set(this.value.steps[i].answers[id], `commentsAnswer`, commentsAnswer)
          })
        })

        this.$emit(`blur-question`, `Init screening data`)
      }
    },
    mounted() {
      if (!this.value) {
        this.$flashMessage.error({
          title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
          message: this.$t(`messages.no_data`)
        })
      }

      if (this.reorder && !!this.$helpers && !!this.$helpers.sortElements) {
        this.value.steps = this.$helpers.sortElements(this.value.steps, (a, b) => a[1].order - b[1].order)
      }

      if (this.allComplete) {
        this.closeAllStep()
      }
    },
    methods: {
      getDOMSteps(listOfIds) {
        const listOfUUID = this.$helpers.wrapInArray(listOfIds)
        if (listOfUUID.length > 0) {
          const renderedSteps = listOfUUID.map((id) => this.$refs[`step_${id}`]?.[0] || this.$refs[`step_${id}`])
          return renderedSteps.length === 1 ? renderedSteps[0] : renderedSteps
        } else {
          return this.value.steps.map((step) => this.$refs[`step_${step.id}`]?.[0] || this.$refs[`step_${step.id}`])
        }
      },
      getCurrentStep() {
        return this.value.steps[this.value.currentStep]
      },
      getCurrentStepInstance() {
        return this.getDOMSteps(this.getCurrentStep().id)
      },
      getCurrentHeaderStepInstance() {
        if (!this.getCurrentStep()?.id) {
          return null
        }
        return this.$refs[`step_header_key_${this.getCurrentStep().id}`]?.[0]
      },
      async openModalNewDocuments({ idStep, idAnswer }) {
        this.newDocumentsStep = idStep
        this.newDocumentsAnswer = idAnswer
        try {
          const { max_size: size, file_extensions: extensions } = await this.$api.screenings.getScreeningStepsDocuments(
            this.value.id,
            this.newDocumentsAnswer
          )
          this.documentsRequirements = { extensions, size }
          this.openModalNewDocumentsValue = true
        } catch (error) {
          if (error.data?.detail) {
            this.$flashMessage.error({
              title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
              message: error.data.detail
            })
          }
        }
      },
      addNewDocuments(data) {
        this.documentsData = data
      },
      saveNewDocuments() {
        if (!!this.documentsData?.new && this.documentsData.new.length > 0) {
          this.$api.screenings
            .saveScreeningStepsDocuments(this.value.id, this.newDocumentsAnswer, this.documentsData)
            .then((res) => {
              if (!!res && !!res._data) {
                // Refresh ?
                const indexStep = this.value.steps.findIndex((step) => step.id === this.newDocumentsStep)
                const indexAnwser = this.value.steps[indexStep].answers.findIndex(
                  (answer) => answer.id === this.newDocumentsAnswer
                )
                this.value.steps[indexStep].answers[indexAnwser].uploaded_documents = res._data
                this.$flashMessage.success({
                  title: this.$t(`messages.success`),
                  message: this.$t(`messages.document_saved`)
                })
                this.closeModalNewDocuments()
              }
            })
            .catch((err) => {
              console.log(err)
              if (err?.response?.status === 400 && !!err.response?._data?.errors?.[0]) {
                this.$flashMessage.error({
                  title: `<${this.$options.name}> : ${this.$t(`messages.cant_save_document`)}`,
                  message: err.response?._data?.errors?.[0]
                })
              } else {
                this.$flashMessage.error({
                  title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                  message: this.$t(`messages.cant_save_document`)
                })
              }
            })
        }
      },
      rulesStepper(step) {
        return () => step.isComplete === undefined || !!step.isComplete
      },
      closeModalNewDocuments() {
        this.openModalNewDocumentsValue = !this.openModalNewDocumentsValue
        this.documentsData = null
      },
      closeAllStep() {
        this.value.currentStep = -1
      },
      displayStepErrors(validationResult) {
        this.stepErrors[validationResult.stepId] = []
        let nbErrors = 0

        if (!!validationResult?.errors) {
          this.stepErrors[validationResult.stepId] = validationResult.errors
        }

        if (!!validationResult?.answers) {
          const answersErrors = validationResult.answers
            .map((a) => {
              // Difference between local (without "fields" key) and remote validation
              const fields = !!a.fields ? a.fields : a
              return Object.values(fields).filter((e) => e.length > 0)
            })
            .filter((a) => a.length > 0)
          nbErrors += answersErrors.reduce((acc, val) => acc + this.$helpers.wrapInArray(val).length, 0)
          if (nbErrors > 0) {
            this.stepErrors[validationResult.stepId].push(
              this.$tc(`pages.screenings.stepErrorMessage`, nbErrors, {
                countError: nbErrors
              })
            )
            this.$store.commit(`screeningsErrors/ADD_LIST`, {
              id: validationResult.stepId,
              text: this.$tc(`pages.screenings.stepErrorMessage`, nbErrors, {
                countError: nbErrors
              })
            })
          }
        }

        if (!validationResult.errors && !validationResult.answers) {
          this.stepErrors[validationResult.stepId] = undefined
        }
      },
      openDocumentsModal({ documents, id = this.value.id }) {
        this.docStepID = id
        this.documentsData = documents
        this.documentsDialog = !this.documentsDialog
      },
      beforeOpenDocumentsModal(stepId) {
        const documents = this.value.steps.find((step) => step.id === stepId).answers[0].uploaded_documents
        this.openDocumentsModal({ documents, id: stepId })
      },
      listDocuments({ step, documents = [] }) {
        step.answers.forEach((answer) =>
          answer.uploaded_documents.forEach((uploadedDocument) => {
            documents.push({ ...uploadedDocument })
          })
        )
        return documents
      },
      validateStep() {
        if (!!this.getCurrentStep()) {
          this.$emit(`send-step`, this.getCurrentStep())
        }
      },
      nextStep() {
        this.displayingStep += 1
      },
      previousStep() {
        this.displayingStep = this.displayingStep > 1 ? this.displayingStep - 1 : 1
      },
      getStepIndexById(stepId) {
        return this.value.steps.findIndex((step) => step.id === stepId)
      },
      getStepAnswerIndexById(stepAnswerId, stepIndex) {
        const index = this.value.steps[stepIndex].answers.findIndex((answer) => answer.id === stepAnswerId)
        return index === -1 ? undefined : index
      },
      updateDocuments(removedDoc, stepId, answerId) {
        const indexStep = this.getStepIndexById(stepId)
        const indexAnswer = this.getStepAnswerIndexById(answerId, indexStep) ?? 0
        const index = this.value.steps[indexStep].answers[indexAnswer].uploaded_documents.findIndex(
          (d) => d.deleteLink === removedDoc.deleteLink
        )
        this.value.steps[indexStep].answers[indexAnswer].uploaded_documents.splice(index, 1)
      },
      deleteDocument(props) {
        if (!!props.doc.deleteLink) {
          const deleteLinkWithoutApi = props.doc.deleteLink.replace(`/frontoffice`, ``)
          this.$api.documents
            .delete(deleteLinkWithoutApi)
            .then(() => {
              this.updateDocuments(props.doc, props.stepId, props.answerId)
              this.$flashMessage.success({
                title: this.$t(`messages.success`),
                message: this.$t(`messages.document_delete`)
              })
            })
            .catch(() => {
              this.$flashMessage.error({
                title: this.$t(`messages.error`),
                message: this.$t(`messages.cant_delete_document`)
              })
            })
        } else {
          this.$flashMessage.error({
            title: this.$t(`messages.error`),
            message: `${this.$t(`messages.cant_delete_document`)} - ${this.$t(`messages.no_available_link`)}`
          })
        }
      },
      gotoStepByUUID(UUID) {
        this.displayingStep = this.value.steps.findIndex((s) => s.id === UUID) + 1
      },
      async validate(specificStep, changeStep = true) {
        if (!!specificStep) {
          const renderedStep = this.getDOMSteps(specificStep)
          const result = !!renderedStep ? await renderedStep.validate({}, changeStep) : false
          renderedStep.goToFirstError()
          return result
        } else {
          const renderedSteps = this.getDOMSteps()
          const result = await Promise.all(renderedSteps.map((step) => step.validate({}, changeStep))).then(() => {
            this.value.isFilled = this.value.steps.every((a) => a.isComplete)
            this.$emit(`input`, this.value)
            return this.value.isFilled
          })
          if (renderedSteps.length > 0) {
            renderedSteps[0].goToFirstError()
          }
          return result
        }
      },
      setErrors(screeningErrors, clearScreeningErrors = false) {
        !!screeningErrors.step_errors && this.setErrorsWithStepErrors(screeningErrors.step_errors)
        !!screeningErrors.steps && this.setErrorsWithSteps(screeningErrors.steps)
        !!screeningErrors.warning_steps && this.setErrorsWithWarningSteps(screeningErrors.warning_steps)

        if (!!screeningErrors.errors && screeningErrors.errors.length > 0) {
          this.setErrorsWithErrors(screeningErrors.errors)
        } else if (clearScreeningErrors) {
          this.displayErrors = []
        }
      },
      setErrorsWithStepErrors(currentStepErrors) {
        // Steps children errors (priority upon general step errors)
        const renderedStep = this.getDOMSteps(currentStepErrors.id)
        renderedStep.setErrors(currentStepErrors)
        this.$helpers.wrapInArray(renderedStep).forEach((rs, i) => {
          if (i === 0) {
            this.value.currentStep = rs.idx
            setTimeout(rs.goToFirstError, 500)
          }
        })
      },
      setErrorsWithSteps(currentStepErrors) {
        // Step errors
        const renderedSteps = this.getDOMSteps(currentStepErrors.map((s) => s.id))
        this.$helpers.wrapInArray(renderedSteps).forEach((rs, i) => {
          rs.setErrors(currentStepErrors[i])
          if (i === 0) {
            this.value.currentStep = rs.idx
            setTimeout(rs.goToFirstError, 500)
          }
        })
      },
      setErrorsWithWarningSteps(currentWarningStep) {
        // warning step

        if (Array.isArray(currentWarningStep)) {
          const renderedSteps = this.getDOMSteps(currentWarningStep)
          this.$helpers.wrapInArray(renderedSteps).forEach((rs, i) => {
            rs.value.isComplete = false
          })
        }
      },
      setErrorsWithErrors(errors) {
        // Screening errors
        this.displayErrors = this.$helpers.wrapInArray(errors)
        this.value.isFilled = false
        this.$emit(`input`, this.value)
        this.$vuetify.goTo(0)
      },
      getInfoBox(id) {
        const infobox = typeof id === `string` ? this.$refs[`step_hint_${id}`][0] : null
        return !!infobox && infobox.$el.childNodes[0].nodeType !== Node.COMMENT_NODE ? infobox.$el.childNodes[0] : null
      },
      scrollTo(props) {
        this.goToStep(props.id)
      },
      focus(id) {
        const uuid = this?.value?.steps[id]?.id || null
        if (uuid) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$helpers.focus({ element: this.$refs[`step_${uuid}`]?.[0]?.$el?.getElementsByTagName(`input`) })
            })
          })
        }
      },
      goToStep(id) {
        if (id !== undefined && id !== null) {
          const DOMAnswer = typeof id === `string` ? this.getDOMSteps(id) : id
          if (!!DOMAnswer) {
            const headerHeight = document.querySelector(`header`)?.offsetHeight || 0
            setTimeout(() => {
              const infobox = this.getInfoBox(id)
              const [target, defaultOffsetHeight] = !!infobox ? [infobox, 20] : [DOMAnswer, 75]
              const offset = headerHeight + defaultOffsetHeight
              this.$vuetify.goTo(target, {
                offset
              })
            }, 500)
          }
        }
      },
      handleLinkClick(event) {
        if (event.target.localName === `a` && !!event.target.dataset.download_file_url) {
          this.$api.documents
            .getOne(event.target.dataset.download_file_url)
            .then((res) => {
              if (!!res && !!res._data) {
                this.$helpers.FileSaver.saveAs(new Blob([res._data]), res.headers.get(`content-filename`))
              }
            })
            .catch(() => {
              this.$flashMessage.error({
                title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
                message: this.$t(`messages.cant_get_document`)
              })
            })
        }
      }
    }
  }
