
  export default {
    name: `AeqSelectionControls`,
    props: {
      errorMessages: {
        type: [String, Array],
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      },
      label: {
        type: String,
        default: ``
      },
      value: {
        type: [Number, String, Array, Object],
        default: null
      },
      multiple: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        innerValue: this.value,
        innerItems: this.items
      }
    },
    computed: {
      traductedErrors() {
        return this.errorMessages.map((e) => this.$t(e))
      }
    },
    watch: {
      innerValue(val) {
        this.$emit(`input`, val)
      }
    },
    mounted() {
      if (this.items.length === 0) {
        this.innerItems.push(
          {
            text: this.$helpers.ucfirst(this.$t(`components.yes`)),
            value: this.$t(`components.yes`)
          },
          {
            text: this.$helpers.ucfirst(this.$t(`components.no`)),
            value: this.$t(`components.no`)
          }
        )
      }
    }
  }
