import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["id", "label", "status", "result", "options", "answers", "readonly", "can_add_answer"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !!_vm.value ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.documentsDialog,
      callback: function callback($$v) {
        _vm.documentsDialog = $$v;
      },
      expression: "documentsDialog"
    }
  }, [_c('v-card', {
    staticClass: "aeq-radius"
  }, [_c('v-card-title', {
    staticClass: "primary"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('pages.client.documents')) + "\n        "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.documentsDialog = !_vm.documentsDialog;
        _vm.documentsData = null;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm._v(" "), _c('v-card-text', {
    staticClass: "pa-0 background-grey"
  }, [_c('AeqDocumentList', {
    key: "doc_list_".concat(_vm.docStepID),
    attrs: {
      "light": ""
    },
    on: {
      "click:delete": function clickDelete($event) {
        return _vm.deleteDocument({
          doc: $event,
          stepId: _vm.docStepID
        });
      }
    },
    model: {
      value: _vm.documentsData,
      callback: function callback($$v) {
        _vm.documentsData = $$v;
      },
      expression: "documentsData"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.openModalNewDocumentsValue,
      callback: function callback($$v) {
        _vm.openModalNewDocumentsValue = $$v;
      },
      expression: "openModalNewDocumentsValue"
    }
  }, [_c('v-card', {
    staticClass: "aeq-radius"
  }, [_c('v-card-title', {
    staticClass: "primary"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('pages.client.documents')) + "\n        "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeModalNewDocuments();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _vm._v(" "), _c('v-card-text', {
    staticClass: "pa-0 background-grey"
  }, [_c('v-col', [_c('AeqFileField', {
    attrs: {
      "label": _vm.$t('pages.client.add_documents'),
      "requirements": _vm.documentsRequirements,
      "multiple": "",
      "outlined": "",
      "small": ""
    },
    on: {
      "input": function input($event) {
        return _vm.addNewDocuments($event);
      }
    },
    model: {
      value: _vm.documentsData,
      callback: function callback($$v) {
        _vm.documentsData = $$v;
      },
      expression: "documentsData"
    }
  })], 1)], 1), _vm._v(" "), _c('v-card-actions', {
    staticClass: "pb-4"
  }, [_c('v-spacer'), _vm._v(" "), _c('v-btn', {
    staticClass: "aeq-radius letter-spacing-0 text-none",
    attrs: {
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.closeModalNewDocuments();
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('pages.screenings.cancel')) + "\n        ")]), _vm._v(" "), _c('v-btn', {
    staticClass: "aeq-radius colorButton--text letter-spacing-0 text-none",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.saveNewDocuments();
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('components.save')) + "\n        ")])], 1)], 1)], 1), _vm._v(" "), _vm.displayErrors.length > 0 ? _c('v-row', [_c('v-col', [_c('v-alert', {
    staticClass: "multi-line",
    attrs: {
      "type": "error"
    }
  }, [_vm.displayErrors.length === 1 ? _c('span', [_vm._v(_vm._s(_vm.displayErrors[0]))]) : _c('ul', _vm._l(_vm.displayErrors, function (e, idx) {
    return _c('li', {
      key: idx
    }, [_vm._v("\n            " + _vm._s(e) + "\n          ")]);
  }), 0)])], 1)], 1) : _vm._e(), _vm._v(" "), _c('v-row', [_c('v-stepper', {
    staticClass: "content-step",
    attrs: {
      "value": _vm.displayingStep,
      "vertical": ""
    }
  }, [_c('v-row', {
    staticClass: "pa-4 px-md-5 pt-md-4",
    attrs: {
      "align": "center",
      "justify": "end",
      "no-gutters": ""
    }
  }, [!!_vm.value.status && _vm.value.transparency_progress ? _c('h4', {
    staticClass: "accent--text mr-2"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('components.screening_status')) + ": " + _vm._s(_vm.$helpers.ucfirst(_vm.value.status.description)) + "\n        ")]) : _vm._e(), _vm._v(" "), !!_vm.value.final_report_document ? _c('v-btn', {
    staticClass: "mr-5",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openDocumentsModal({
          documents: [_vm.value.final_report_document]
        });
      }
    },
    nativeOn: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-file-document-outline")])], 1) : _vm._e()], 1), _vm._v(" "), _vm._l(_vm.value.steps, function (_ref, index) {
    var id = _ref.id,
      label = _ref.label,
      status = _ref.status,
      result = _ref.result,
      options = _ref.options,
      answers = _ref.answers,
      readonly = _ref.readonly,
      can_add_answer = _ref.can_add_answer,
      step = _objectWithoutProperties(_ref, _excluded);
    return [_c('v-stepper-step', {
      key: "step_header_key_".concat(id),
      ref: "step_header_".concat(id),
      refInFor: true,
      staticClass: "v-stepper__step--editable pl-4",
      attrs: {
        "complete": _vm.value.steps[index].isComplete,
        "is-active": index === _vm.displayingStep,
        "rules": [_vm.rulesStepper(_vm.value.steps[index])],
        "step": index + 1,
        "edit-icon": "$complete"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.gotoStepByUUID(id);
        }
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "dense": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "order": "first",
        "sm": ""
      }
    }, [_c('strong', {
      staticClass: "black--text"
    }, [_vm._v(_vm._s(label))]), _vm._v(" "), status && _vm.value.transparency_progress ? _c('div', {
      staticClass: "text-caption black--text"
    }, [_vm._v("\n                " + _vm._s(_vm.$helpers.ucfirst(status.description)) + "\n              ")]) : _vm._e(), _vm._v(" "), _vm.value.currentStep === index ? _c('ErrorHandlerScreening', {
      attrs: {
        "id": id
      }
    }) : _vm._e()], 1), _vm._v(" "), result && _vm.value.transparency_result ? _c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "auto",
        "order": "1",
        "order-lg": "2"
      }
    }, [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": !!result.color ? "#".concat(result.color) : ''
            }
          }, 'v-icon', attrs, false), on), [_vm._v("\n                    " + _vm._s(!!result && !!result.code && !!result.color ? 'mdi-circle' : 'mdi-circle-outline') + "\n                  ")])];
        }
      }], null, true)
    }, [_vm._v(" "), _c('span', {
      staticClass: "text-caption"
    }, [_vm._v(_vm._s(_vm.$helpers.ucfirst(result.description)))])])], 1) : _vm._e(), _vm._v(" "), options.additional_documents && options.additional_documents.value ? _c('v-col', {
      staticClass: "text-left text-md-right text-lg-right",
      attrs: {
        "cols": "12",
        "lg": "auto",
        "md": "2",
        "order": "last"
      }
    }, [answers.some(function (a) {
      return !!a.uploaded_documents && a.uploaded_documents.length > 0;
    }) && !!options.multiple_answers && options.multiple_answers.value ? [_c('v-badge', {
      staticClass: "mr-11 ml-2",
      attrs: {
        "content": answers.reduce(function (acc, a) {
          return acc + a.uploaded_documents.length;
        }, 0),
        "offset-x": "10",
        "offset-y": "10"
      }
    }, [_c('v-icon', [_vm._v("mdi-file-multiple")])], 1)] : answers.some(function (a) {
      return !!a.uploaded_documents && a.uploaded_documents.length > 0;
    }) ? [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.beforeOpenDocumentsModal(id);
        }
      },
      nativeOn: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('v-badge', {
      attrs: {
        "content": answers.reduce(function (acc, a) {
          return acc + a.uploaded_documents.length;
        }, 0),
        "offset-x": "10",
        "offset-y": "10"
      }
    }, [_c('v-icon', [_vm._v("mdi-file")])], 1)], 1)] : [_c('v-icon', {
      staticClass: "ml-2",
      class: !!options.multiple_answers && options.multiple_answers.value ? 'mr-11' : 'mr-1'
    }, [_vm._v("\n                  mdi-file-outline\n                ")])], _vm._v(" "), !(!!options.multiple_answers && options.multiple_answers.value) ? [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openModalNewDocuments({
            idStep: id,
            idAnswer: answers[0].id
          });
        }
      },
      nativeOn: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-1 ml-2"
    }, [_vm._v(" mdi-file-plus-outline ")])], 1)] : _vm._e()], 2) : _vm._e(), _vm._v(" "), _c('v-col', {
      attrs: {
        "cols": "auto",
        "order": "last"
      }
    }, [_c('div', {
      style: _vm.$vuetify.breakpoint.lgAndUp ? 'width: 40px' : ''
    })])], 1)], 1), _vm._v(" "), !!_vm.value.steps[_vm.value.currentStep] && _vm.value.steps[_vm.value.currentStep].id === id ? [_c('AeqInfoBox', {
      key: "step_hint_".concat(id),
      ref: "step_hint_".concat(id),
      refInFor: true,
      attrs: {
        "id": "".concat(id),
        "value": _objectSpread({}, _vm.infoBox),
        "alignement": "bottom"
      },
      on: {
        "on-box-click": function onBoxClick($event) {
          return _vm.handleLinkClick($event);
        }
      }
    })] : _vm._e(), _vm._v(" "), _c('v-stepper-content', {
      key: "step_".concat(index),
      staticClass: "py-2",
      attrs: {
        "step": index + 1
      }
    }, [_c('Step', {
      key: "step_".concat(id),
      ref: "step_".concat(id),
      refInFor: true,
      attrs: {
        "current-step": id,
        "headless": !!options.multiple_answers && !options.multiple_answers.value,
        "idx": index,
        "info-box": _vm.infoBox
      },
      on: {
        "error": function error($event) {
          return _vm.displayStepErrors($event);
        },
        "blur-question": function blurQuestion($event) {
          return _vm.$emit('blur-question', _objectSpread({
            stepIndex: index
          }, $event));
        },
        "add-answer": function addAnswer($event) {
          return _vm.$emit('add-answer', $event);
        },
        "add-new-documents": function addNewDocuments($event) {
          return _vm.openModalNewDocuments($event);
        },
        "del-answer": function delAnswer($event) {
          return _vm.$emit('del-answer', $event);
        },
        "change-answer": function changeAnswer($event) {
          return _vm.$emit('change-answer', $event);
        },
        "send-answer": function sendAnswer($event) {
          return _vm.$emit('send-answer', $event);
        },
        "delete-doc": function deleteDoc($event) {
          return _vm.deleteDocument($event);
        },
        "scroll-to": function scrollTo($event) {
          return _vm.scrollTo($event);
        }
      },
      model: {
        value: _vm.value.steps[index],
        callback: function callback($$v) {
          _vm.$set(_vm.value.steps, index, $$v);
        },
        expression: "value.steps[index]"
      }
    }), _vm._v(" "), _vm.commandButton ? _c('v-row', {
      staticClass: "mb-2"
    }, [_c('v-col', {
      staticClass: "text-right d-flex flex-column-reverse flex-sm-row justify-end gap-15 xs-gap-15",
      attrs: {
        "cols": "12"
      }
    }, [!!options.multiple_answers && options.multiple_answers.value && can_add_answer ? _c('v-btn', {
      staticClass: "aeq-radius colorButton--text letter-spacing-0 text-none",
      attrs: {
        "disabled": !can_add_answer,
        "color": "primary",
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('add-answer', id);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(" mdi-plus-circle-outline ")]), _vm._v("\n                " + _vm._s(step.new_answer_label || _vm.$helpers.parseTemplate(_vm.$t('pages.screenings.add'), {
      label: {
        answer: _vm.$helpers.ucfirst(label.toLocaleLowerCase())
      }
    }, '')) + "\n              ")], 1) : _vm._e(), _vm._v(" "), !readonly ? _c('v-btn', {
      staticClass: "aeq-radius colorButton--text letter-spacing-0 text-none mr-sm-4 mx-0",
      attrs: {
        "disabled": readonly,
        "color": "primary",
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.validateStep();
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.$t('components.validate')) + "\n              ")]) : _vm._e()], 1)], 1) : _vm._e()], 1)];
  })], 2)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };