
  export default {
    name: `ErrorHandlerScreening`,
    props: {
      id: {
        type: String,
        default: ``,
        required: true
      },
      dynamicClass: {
        type: String,
        default: ``
      }
    },
    computed: {
      errors() {
        const stepErrors = this.$store.state.screeningsErrors.stepErrors
        return stepErrors.filter((value) => value.id === this.id)
      }
    }
  }
