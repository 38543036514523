
  export default {
    name: `AeqInfoBox`,
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      id: {
        type: String,
        default: ``,
        required: true
      },
      boxClassParent: {
        type: String,
        default: ``
      },
      alignement: {
        type: String,
        default: `top`,
        validator(value) {
          // The value must match one of these strings
          return [`top`, `bottom`].includes(value)
        }
      },
      parentIsOpen: {
        type: Boolean,
        default: true
      },
      boxClassName: {
        type: String,
        default: ``
      }
    },
    data() {
      return {
        isOpen: false,
        isOpen2: false
      }
    },
    computed: {
      hints() {
        const { hints } = this?.value || {}
        return typeof hints === `object` && hints.length > 0 ? hints.filter((value) => value.id === this.id) : []
      },
      comments() {
        const { comments } = this?.value || {}
        return typeof comments === `object` && comments.length > 0
          ? comments.filter((value) => value.resource_id === this.id)
          : []
      },
      dynamicClassColor() {
        return `${this.comments.length > 0 ? `comments` : ``}${this.hints.length > 0 ? ` hints` : ``} `
      }
    },
    methods: {
      ontoggle(event) {
        this.isOpen = !this.isOpen
      },
      valueFilter(values, prop = `id`) {
        return typeof values === `object` && values.length > 0 ? values.filter((value) => value[prop] === this.id) : []
      }
    }
  }
